.row_container {
  min-height: 100vh;
}

.side_container {
  background-color: #363636;
  padding: 0;
}

.main_container {
  text-align: center;
  background-color: #1f1f1f;
  padding-top: 10px;
  /* background-image: url('https://i.imgur.com/3EXURf8.jpg'); */
  /* background-position: center;  */
  /* background-size: cover; */
}

.side_tab {
  background-color: #f5f5f5;
  height: 50px;
  font-size: 0.9em;
  width: 100%;
  text-align: center;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid black;
}

.side_tab:hover {
  background-color: #d8d8d8;
  cursor: pointer;
  transition-duration: 0.2s;
}
